@import url("./style.module.css");
@import url("./font/style.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "SF UI Text";
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
button{
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn-hover::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  background: transparent;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.btn-hover:hover::after{
  background: #00000038;
}

.website-container {
  --spacing: 16px;
  --container-width: 1240px;
  max-width: calc(var(--container-width) + var(--spacing) * 2);
  padding: var(--spacing);
  margin: auto;
  width: 100%;
}

.text-gradient {
  background: var(--gradient-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1.63px;
  font-weight: 400;
}
